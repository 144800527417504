<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import type { Dish } from '@/types/dish'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { computed } from 'vue';

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const props = defineProps<{
  dish: Dish | null
}>()

// const nutritionalFacts = computed(() => {
//   if (!props.dish) return [];
//   });
</script>

<template>
  <div v-if="dish" class="p-4 border border-gray-200 rounded-md shadow-md max-w-md mx-auto">
    <h1 class="text-lg font-bold mb-4">{{ t('dish.nutritional.forOneServing') }}</h1>

    <!-- <DataTable :value="dish" tableStyle="min-width: 50rem">
      <Column field="name" header=""></Column>
      <Column field="unit" header=""></Column>
      <Column field="quantity" header="Quantity"></Column>
    </DataTable> -->

    <table class="w-full text-sm">
      <tbody>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.calories') }}</td>
          <td class="text-right">{{ dish.calories_kcal }} kcal</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.protein') }}</td>
          <td class="text-right">{{ dish.protein_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.fat') }}</td>
          <td class="text-right">{{ dish.fat_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.saturatedFat') }}</td>
          <td class="text-right">{{ dish.saturated_fat_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.carbohydrates') }}</td>
          <td class="text-right">{{ dish.carbohydrates_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.sugars') }}</td>
          <td class="text-right">{{ dish.sugars_g }} g</td>
        </tr>
        <tr class="border-b border-gray-300">
          <td class="py-2 font-medium">{{ t('dish.nutritional.dietaryFiber') }}</td>
          <td class="text-right">{{ dish.dietary_fiber_g }} g</td>
        </tr>
        <tr>
          <td class="py-2 font-medium">{{ t('dish.nutritional.salt') }}</td>
          <td class="text-right">{{ dish.salt_sodium_g }} g</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped></style>
