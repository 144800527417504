<!-- MenuView.vue -->
<script setup lang="ts">
import type { MessageSchema } from '@/i18n';
import { usePlanningStore } from '@/stores/planningStore';
import type { PlannedMenu } from '@/types/planning';
import Checkbox from 'primevue/checkbox';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';


const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// Define props for the component
const props = defineProps<{
  date: Date
  plannedMenu: PlannedMenu
}>();

const route = useRoute()
const router = useRouter()
const planningStore = ref(usePlanningStore());

const isSelected = computed(() => {
  return planningStore.value.dishToPlan?.selectedPlannedMenuIds.includes(props.plannedMenu.id) || planningStore.value.dishTitleToPlan?.selectedPlannedMenuIds.includes(props.plannedMenu.id) || false;
});

const isDishPlanning = computed(() => {
  return planningStore.value.dishToPlan !== null || planningStore.value.dishTitleToPlan !== null;
});
// Define a list of dishes for the current day
const dishes = ref<string[]>([]);

// Add a new dish to the list
const menuCourses = ref(props.plannedMenu.menu_courses);

function goToMenuDetails() {
  // if (planningStore.value.dishToPlanClipboard) {
  //   // You're in the modal, so display the menu details in the sidebar
  //   planningStore.value.selectedPlannedMenu = props.plannedMenu;
  //   // Trigger any sidebar opening logic if needed
  // } else {
  // Not in modal mode, proceed with the usual navigation
  router.push(`/planning/planned-menu/${props.plannedMenu.id}`);
  // }
}

const sortedAndFilledCourses = computed(() => {
  const courseTypes = [1, 2, 3]; // Represents Entrée, Plat principal, Dessert
  const courses = props.plannedMenu.menu_courses.slice().sort((a, b) => a.order - b.order);

  // Create a new array that includes all three types, filling in missing types with placeholders
  return courseTypes.map(type => {
    const foundCourse = courses.find(course => course.order === type);
    return foundCourse ? foundCourse : { id: `missing-${type}`, name: '', dish: { name: '***' }, order: type };
  });
});

function handleClickPlannedMenu() {
  if (!props.plannedMenu.tempId) {
    if (planningStore.value.dishToPlan) {
      const plannedMenuId = props.plannedMenu.id;
      const selectedIds = planningStore.value.dishToPlan.selectedPlannedMenuIds;

      if (selectedIds.includes(plannedMenuId)) {
        // Remove the ID if it's already selected
        planningStore.value.dishToPlan.selectedPlannedMenuIds = selectedIds.filter(id => id !== plannedMenuId);
      } else {
        // Add the ID if it's not yet selected
        planningStore.value.dishToPlan.selectedPlannedMenuIds.push(plannedMenuId);
      }
    } else if (planningStore.value.dishTitleToPlan) {
      const plannedMenuId = props.plannedMenu.id;
      const selectedIds = planningStore.value.dishTitleToPlan.selectedPlannedMenuIds;

      if (selectedIds.includes(plannedMenuId)) {
        // Remove the ID if it's already selected
        planningStore.value.dishTitleToPlan.selectedPlannedMenuIds = selectedIds.filter(id => id !== plannedMenuId);
      } else {
        // Add the ID if it's not yet selected
        planningStore.value.dishTitleToPlan.selectedPlannedMenuIds.push(plannedMenuId);
      }
    } else {
      // If no dish is being planned, navigate to menu details
      goToMenuDetails();
    }
  }
}

const checkboxPt = {
  root: { class: 'custom-checkbox' },
  // input: { class: 'custom-checkbox-input' },
  box: { style: 'width: 1.5rem; height: 1.5rem; border: 2px solid gray' },
  icon: { style: 'font-size: 1rem;' }
};
</script>

<template>
  <!-- Right side for the card -->
    <div v-if="props.plannedMenu.tempId" class=" opacity-50 flex items-start bg-gray-200 flex-grow relative py-8 px-4 rounded-xl shadow-lg my-4 mx-2 w-52 h-56">
      <div
        class="absolute -top-4 left-1/2 -translate-x-1/2 bg-gray-300 text-black py-2 px-4 font-bold rounded-md whitespace-nowrap overflow-hidden text-ellipsis max-w-36 text-center text-xs truncate">
      <p class="w-24 h-4"></p>
      </div>
      <div class=" flex flex-col gap-3 justify-around text-sm">
      </div>
    </div>
  <div class="relative" v-if="!props.plannedMenu.tempId">

    <div @click="handleClickPlannedMenu"
      class="cursor-pointer flex items-start bg-primary-200 flex-grow relative py-8 px-4 rounded-xl shadow-lg my-4 mx-2 w-52 h-56"
      :class="{
        'opacity-60': !isSelected && isDishPlanning
      }">
      <div
        class="absolute -top-4 left-1/2 -translate-x-1/2 bg-primary-600 text-white py-2 px-4 font-bold rounded-md whitespace-nowrap overflow-hidden text-ellipsis max-w-36 text-center text-xs truncate">
        {{ props.plannedMenu.name }}
      </div>
      <div class=" flex flex-col gap-3 justify-around text-sm">
        <ul class="list-none flex flex-col">
          <li v-for="menuCourse in sortedAndFilledCourses" :key="menuCourse.id" class="relative w-44">
            <div :class="{ 'justify-center': menuCourse.dish?.name === '***' || menuCourse?.name === '***' }"
              class="h-12 text-ellipsis flex items-center">
              <p class="line-clamp-3">{{ menuCourse.dish?.name ?? menuCourse.name }}</p>
            </div>
            <div v-if="menuCourse.order !== 3" class="h-0.5 w-6 bg-primary-500 rounded-full mx-auto my-2">
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="isDishPlanning" class="absolute top-2 right-2">
      <Checkbox :binary="true" :model-value="isSelected" class="m-2" :pt="checkboxPt" @click="handleClickPlannedMenu"/>
    </div>
  </div>
</template>

<style scoped></style>