<script setup lang="ts">
import SupplierCard from '@/components/Profile/Suppliers/SupplierCard.vue'
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Supplier } from '@/types/api.js'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref, watch, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import AutoComplete from 'primevue/autocomplete'
import { useRouter } from 'vue-router'
import LoadingView from '../LoadingView.vue'
import { useUserStore } from '@/stores/useUserStore'

const toast = useToast()
const envStore = useEnvStore()
const user = ref(useUserStore().user)
const { fetchData } = useFetch()
const router = useRouter()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const mounted = ref(false)

const restaurantUuid: Ref<string | null> = ref(null)
const mySuppliers: Ref<Supplier[]> = ref([])
const allSuppliers: Ref<Supplier[]> = ref([])
const allSuppliersSearch: Ref<string> = ref('')
const isSearching: Ref<boolean> = ref(false)
const totalSuppliers: Ref<number> = ref(0)
const currentPage: Ref<number> = ref(1)
const itemsPerPage = 20

const otherSuppliers = computed(() => {
  return allSuppliers.value.filter(
    (supplier) => !mySuppliers.value.some((mySupplier) => mySupplier.uuid === supplier.uuid)
  )
})

function toggleFavorite(action: 'add' | 'remove', supplierUuid: string) {
  let toastText = ''
  if (action === 'add') {
    const foundSupplier = allSuppliers.value.find((supplier) => supplier.uuid === supplierUuid)
    if (!foundSupplier) return
    toastText = t('profile.suppliers.favoriteSupplierAdded')
    mySuppliers.value.push(foundSupplier)
  } else {
    mySuppliers.value = mySuppliers.value.filter((supplier) => supplier.uuid !== supplierUuid)
    toastText = t('profile.suppliers.favoriteSupplierRemoved')
  }
  toast.add({
    severity: 'success',
    summary: t('common.success'),
    detail: toastText,
    life: 3000,
  })
}

async function fetchSuppliers() {
  const searchQuery = allSuppliersSearch.value ? `&name=${allSuppliersSearch.value}` : ''
  const searchUrl = `${envStore.apiUrl}/suppliers?page=${currentPage.value}&itemsPerPage=${itemsPerPage}${searchQuery}`

  isSearching.value = true

  const { data, error } = await fetchData(searchUrl, 'GET')

  if (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('profile.suppliers.getSuppliersError'),
      life: 3000,
    })
  } else {
    if (currentPage.value === 1) {
      allSuppliers.value = [...data.data] as Supplier[]
    } else {
      allSuppliers.value = [...allSuppliers.value, ...data.data] as Supplier[]
    }
    totalSuppliers.value = data.total
  }

  isSearching.value = false
}

function saveState() {
  const stateToSave = {
    mySuppliers: mySuppliers.value,
    allSuppliers: allSuppliers.value,
    totalSuppliers: totalSuppliers.value,
    currentPage: currentPage.value,
    allSuppliersSearch: allSuppliersSearch.value,
    scrollPosition: window.scrollY,
  }
  sessionStorage.setItem('suppliersState', JSON.stringify(stateToSave))
}

async function restoreState() {
  const savedState = sessionStorage.getItem('suppliersState')
  if (savedState) {
    const state = JSON.parse(savedState)
    mySuppliers.value = state.mySuppliers
    allSuppliers.value = state.allSuppliers
    totalSuppliers.value = state.totalSuppliers
    currentPage.value = state.currentPage
    allSuppliersSearch.value = state.allSuppliersSearch
    const scrollPosition = state.scrollPosition

    scrollWithRetry(scrollPosition)

    sessionStorage.removeItem('suppliersState')
  } else {
    await fetchSuppliers()
  }
}

function scrollWithRetry(position, maxAttempts = 5) {
  let attempts = 0
  const scrollInterval = setInterval(() => {
    window.scrollTo(0, position)
    attempts++
    if (window.scrollY === position || attempts >= maxAttempts) {
      clearInterval(scrollInterval)
    }
  }, 20)
}

watch(allSuppliersSearch, async () => {
  if (allSuppliersSearch.value === '') {
    await fetchSuppliers()
  }
})

onMounted(async () => {
  if (user.value) {
    restaurantUuid.value = user.value?.restaurants[0].id
    fetchData(`${envStore.apiUrl}/restaurants/${restaurantUuid.value}/suppliers`).then(
      (response) => {
        if (response.data) {
          mySuppliers.value = response.data as Supplier[]
        } else if (response.error) {
          toast.add({
            severity: 'error',
            summary: t('common.error'),
            detail: t('profile.suppliers.getSuppliersError'),
            life: 3000,
          })
        }
      }
    )
  }

  await restoreState()
  console.log('mySuppliers', mySuppliers.value)
  mounted.value = true
})

function handleCardClick(supplierUuid: string) {
  saveState()
  router.push(`/suppliers/${supplierUuid}`)
}
</script>

<template>
  <main>
    <div v-if="mounted">
      <div>
        <div class="flex items-center justify-between mb-2">
          <h3>{{ t('profile.suppliers.mySuppliers') }}</h3>
          <Button icon="pi pi-plus" @click="router.push({ name: 'create-supplier' })" />
        </div>
        <div class="flex flex-col gap-2">
          <SupplierCard
            v-for="supplier in mySuppliers"
            :key="supplier.uuid"
            :supplier="supplier"
            :is-favorite="true"
            @update-favorite="toggleFavorite('remove', supplier.uuid)"
            @click="handleCardClick(supplier.uuid)"
          />
        </div>
      </div>

      <div class="mt-10">
        <h3 class="mb-2">{{ t('profile.suppliers.otherSuppliers') }}</h3>
        <AutoComplete
          v-model="allSuppliersSearch"
          @complete="fetchSuppliers"
          panel-class="hidden"
          :suggestions="allSuppliers"
          :loading="isSearching"
          :placeholder="t('profile.suppliers.searchPlaceholder')"
          class="mb-2"
        />
        <div class="flex flex-col gap-2">
          <SupplierCard
            v-for="supplier in otherSuppliers"
            :key="supplier.uuid"
            :supplier="supplier"
            :is-favorite="false"
            @update-favorite="toggleFavorite('add', supplier.uuid)"
            @click="handleCardClick(supplier.uuid)"
          />
        </div>
        <Button
          v-if="allSuppliers.length < totalSuppliers"
          :label="t('common.seeMore')"
          class="mt-4"
          @click="
            () => {
              currentPage++
              fetchSuppliers()
            }
          "
        />
      </div>
    </div>
    <div v-else>
      <LoadingView />
    </div>
  </main>
</template>
