import type { ToastServiceMethods } from 'primevue/toastservice'
import { useFetch } from './useFetch'
import { useEnvStore } from '@/stores/envStore'
import i18n from '@/i18n'
import type { Restaurant } from '@/types/restaurants'

const { fetchData } = useFetch()
const { t } = i18n.global

export function useProfileRestaurant(toast: ToastServiceMethods, restaurantId: string) {
  async function updateRestaurantProperty(
    propertyName: string,
    value: string | number
  ): Promise<Restaurant | null> {
    const url = useEnvStore().apiUrl + '/restaurants/' + restaurantId
    const { error, data } = await fetchData(url, 'PATCH', { [propertyName]: value })

    if (error) {
      console.log(error)
      if (error.details.message) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: error.details.message,
          life: 3000,
        })
      } else {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profile.profile.restaurantTab.updateError'),
          life: 3000,
        })
      }
      return null
    }
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('profile.profile.restaurantTab.updateSuccess'),
      life: 3000,
    })

    return data as Restaurant
  }

  async function updateRestaurantAttributes(
    { attributeIds, isRestaurationType = false, toggleAll = false }:
      { attributeIds: number[]; isRestaurationType?: boolean; toggleAll?: boolean; }
  ): Promise<Restaurant | null> {
    const url = useEnvStore().apiUrl + '/restaurants/' + restaurantId

    const body = isRestaurationType ? { restaurationTypes: attributeIds } : { attributes: attributeIds, toggleAll }

    console.log(body)

    const { error, data } = await fetchData(url, 'PATCH', body)

    if (error) {
      console.log(error)
      if (error.details.message) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: error.details.message,
          life: 3000,
        })
      } else {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profile.profile.restaurantTab.updateError'),
          life: 3000,
        })
      }
      return null
    }
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('profile.profile.restaurantTab.updateSuccess'),
      life: 3000,
    })

    return data as Restaurant
  }

  return { updateRestaurantProperty, updateRestaurantAttributes }
}
