<script setup lang="ts">
import { computed, markRaw, onMounted, ref, type Ref } from 'vue'
// import { useI18n } from 'vue-i18n'
// import type { MessageSchema } from '@/i18n/index.js'
import Button from 'primevue/button'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import { watch } from 'vue'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useDishesStore } from '@/stores/dishesStore'
import MyRecipesMenuItemInDialog from './MyRecipesMenuItemInDialog.vue'
import { useUserStore } from '@/stores/useUserStore'

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const emits = defineEmits(['select-category', 'select-tag'])

const openDishTagModal: Ref<boolean> = ref(false)
const newTagName: Ref<string> = ref('');
// const myDishTags: Ref<DishTag[] | []> = ref([])
const { fetchData } = useFetch()
const envStore = useEnvStore()
const toast = useToast()
const user = ref(useUserStore().user)
const dishesStore = useDishesStore();
const fetchingTags = computed(() => dishesStore.fetchingTags);
const restaurantId = user.value?.restaurants[0]?.id || null

const links = [
  {
    name: t('pageTitle.myFavorites'),
    icon: null,
    category: 'favorites',
  },
]

const myCategories = computed(() => {
  return dishesStore.tags.map((tag) => ({
    name: tag.name,
    icon: null,
    category: `tags`,
    tag: tag.id,
  }));
});


async function addDishTag() {

  if (newTagName.value.trim() !== '') {
    const newTag = {
      name: newTagName.value.trim(),
    };

    try {
      const payload = {
        tags: [newTag],
      }
      const response = await fetchData(`${envStore.apiUrl}/dishes/tags/${restaurantId}`, 'POST', payload);

      if (response.data) {
        dishesStore.tags = [...dishesStore.tags, ...response.data.tags];
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('dishTag.add.success'),
          life: 3000,
        });
      } else if (response.error) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: response.error,
          life: 3000,
        });
      }
    } catch (error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('dishTag.add.error'),
        life: 3000,
      });
    }
  }

  openDishTagModal.value = false;
}

onMounted(async () => {
  await dishesStore.fetchTags(restaurantId, toast);
})

watch(openDishTagModal, (newValue) => {
  if (!newValue) {
    newTagName.value = '';
  }
});
</script>

<template>
  <main>
    <MyRecipesMenuItemInDialog v-for="(link, index) in links" :key="link.name" :name="link.name"
      :icon="link?.icon ?? undefined" :index="index" @click="$emit('select-category', link.category)" />
    <div class="my-7">
      <h1 class="text-2xl">{{ t('homeView.myRecipes.myCategories') }}</h1>
      <div class="mt-4">
        <p v-if="fetchingTags" class="mt-4 italic">{{ t('common.loading') }}</p>
        <p v-else-if="!fetchingTags && myCategories.length === 0" class="mt-4 italic">{{ t('dishTag.noTags') }}</p>
        <MyRecipesMenuItemInDialog v-else v-for="(link, index) in myCategories" :key="link.name" :name="link.name"
          :icon="link?.icon ?? undefined" :index="index" @click="$emit('select-tag', link.tag)" />
      </div>
      <!-- <Button @click="null" :label="t('common.seeMore')" /> -->
      <div class="mt-4">
        <p @click="openDishTagModal = true" class="mb-10 inline-button">
          {{ t('dishTag.add.label') }}
        </p>
      </div>
    </div>
    <Dialog v-model:visible="openDishTagModal" modal :header="t('dishTag.add.label')" :style="{ width: '25rem' }">
      <div v-if="openDishTagModal" class="flex items-center gap-4 mb-4">
        <label for="dishTag" class="font-semibold w-24">{{ t('dishTag.tagName') }}</label>
        <InputText id="dishTag" v-model="newTagName" class="flex-auto" autocomplete="off" />
      </div>
      <div class="flex justify-between gap-2">
        <Button type="button" :label="t('common.cancel')" outlined @click="openDishTagModal = false"
          :disabled="false"></Button>
        <div class="flex justify-end gap-2">
          <!-- <Button v-if="true" type="button"
            :label="t('common.generate')" @click="null" severity="secondary"
            :disabled="false"></Button> -->
          <Button type="button" :label="t('common.save')" @click="addDishTag()" :disabled="false"></Button>
        </div>
      </div>
    </Dialog>
  </main>
</template>
