<script setup lang="ts">
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useDishesStore, useRecipeStore } from '@/stores/dishesStore.js'
import { useEnvStore } from '@/stores/envStore.js'
import SpeedDial from 'primevue/speeddial'
import { useToast } from 'primevue/usetoast'
import { ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const emit = defineEmits<{
  (e: 'updateAnalyzeStatus', boolean): boolean
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const toast = useToast()
const router = useRouter()
const { fetchData } = useFetch()

const scanInput: Ref<HTMLInputElement | null> = ref(null)
const fileInput: Ref<HTMLInputElement | null> = ref(null)

const scanMenuItems = ref([
  {
    label: 'Photo',
    icon: 'pi pi-camera',
    command: () => {
      scanInput.value?.click()
    },
  },
  {
    label: 'File',
    icon: 'pi pi-file',
    command: () => {
      fileInput.value?.click()
    },
  },
])

const file: Ref<File | null> = ref(null)

function handleFileChange(event) {
  const files = event.target.files
  if (files.length > 0) {
    file.value = files[0]
    analyzeImage()
  } else {
    file.value = null
  }
}

function analyzeImage() {
  if (!file.value) return
  console.log('Analyzing dish...')
  emit('updateAnalyzeStatus', true)

  const formData = new FormData()
  formData.append('image', file.value)

  fetchData(`${useEnvStore().apiUrl}/dishes/import/analyze`, 'POST', formData, 105000)
    .then(({ data, error }) => {
      if (error) {
        console.error('Upload failed:', error)
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profileFavorites.analyzeError'),
          life: 3000,
        })
        return
      } else {
        console.log('Upload successful:')
        console.log(data)
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('profileFavorites.analyzeSuccess'),
          life: 3000,
        })

        useRecipeStore().recipe = data.recipe
        useDishesStore().selectedDish = data.dish

        // useOcrDishStore().ocrDish = data
        useDishesStore().selectedDish!.ingredients = useDishesStore().selectedDish!.ingredients.map(
          (ingredient) => {
            if (ingredient.quantity === 0) {
              ingredient.quantity = undefined
            }
            if (ingredient.quantity === undefined && ingredient.unit) {
              ingredient.unit = ''
            }
            return ingredient
          }
        )

        router.push({ path: `/menu/dishes/${useDishesStore().selectedDish!.id}/recipe-steps` })
        return
      }
    })
    .finally(() => emit('updateAnalyzeStatus', false))
}
</script>

<template>
  <div>
    <SpeedDial
      :model="scanMenuItems"
      :radius="120"
      type="linear"
      direction="up"
      class="!fixed bottom-24 right-8"
    />
    <input
      ref="scanInput"
      type="file"
      name="recipe-scan"
      id="recipe-scan"
      accept="image/jpeg, image/jpg, image/png, image/webp, image/heic"
      capture="environment"
      @change="handleFileChange"
      class="hidden mb-4"
    />
    <input
      ref="fileInput"
      type="file"
      name="recipe-file"
      id="recipe-file"
      accept="image/jpeg, image/jpg, image/png, image/webp, image/heic"
      @change="handleFileChange"
      class="hidden mb-4"
    />
  </div>
</template>
