<script setup lang="ts">
import type { MessageSchema } from '@/i18n'
import { usePlanningStore } from '@/stores/planningStore'
import { type Dish, type Ingredient } from '@/types/dish'
import GenerationHistoryView from '@/views/MyRecipes/GenerationHistoryView.vue'
import MyRecipesView from '@/views/MyRecipes/MyRecipesView.vue'
import Sidebar from 'primevue/sidebar'
import { computed, ref, watch, type ModelRef } from 'vue'
import { useI18n } from 'vue-i18n'
import MyRecipesInDialogView from '../Menus/MenuDishViewComponents/PlanDishFromDialog/MyRecipesInDialog.vue'
import MyScansView from '@/views/MyRecipes/MyScansView.vue'
import PlannedView from '@/views/MyRecipes/PlannedView.vue'
import DishTagsView from '@/views/MyRecipes/DishTagsView.vue'
import TagView from '@/views/MyRecipes/TagView.vue'
import FavoritesView from '@/views/MyRecipes/FavoritesView.vue'
import DishTagsInDialog from '../Menus/MenuDishViewComponents/PlanDishFromDialog/DishTagsInDialog.vue'
import Button from 'primevue/button'
import GeneratedTitlesView from '@/views/MyRecipes/GeneratedTitlesView.vue'
import { useUserStore } from '@/stores/useUserStore'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

// const { categories } = useCategories();
const showSideBar: ModelRef<boolean | undefined> = defineModel('visible', { required: true })
const sideBarIngredient: ModelRef<Ingredient | undefined> = defineModel('ingredient')
// const ingredientCategory = { name: t('orders.ingredientsCategories.other'), value: 'other' }
// const dishesStore = useDishesStore();
// const ingredientUnits = ref(dishesStore.units);
// const { isFetching, fetchData } = useFetch()

const user = ref(useUserStore().user)
const planningStore = usePlanningStore()

const edition = ref(false)
const dishName = computed(() => {
  return planningStore.dishToPlan?.clipboardDish.name ?? ''
})

const emit = defineEmits<{
  (e: 'addDishToPlan', dish: Dish): void
  (e: 'addDishTitleToPlan', dishTitle: Dish): void
}>()

// const ingredient = ref(props.ingredient);
const category = ref('')
const subCategory = ref('') // For tag subcategories
const showTagView = ref(false);


const props = defineProps<{
  // ingredients: Ingredient[]
  // category: 'history' | 'favorites'
  plannedMenuName: string
}>()

watch(showSideBar, (newValue) => {
  if (!newValue) {
    planningStore.choosingDishToPlan = false
  }
})

function addDishToPlan(dish: Dish) {
  if (!dish) return
  emit('addDishToPlan', dish)
}

function addDishTitleToPlan(dishTitle: Dish) {
  if (!dishTitle) return
  emit('addDishTitleToPlan', dishTitle)
}

const selectCategory = (newCategory) => {
  category.value = newCategory
  if (newCategory === 'tags') {
    showTagView.value = true
  } else {
    showTagView.value = false
  }
}

const selectTag = (tag) => {
  subCategory.value = tag
  showTagView.value = false
}

function goBack() {
  if (subCategory.value) {
    // If there's a subcategory, clear it and go back to tags view
    subCategory.value = ''
    showTagView.value = true
  } else if (category.value) {
    // If there's only a category, clear it and go back to main view
    category.value = ''
    showTagView.value = false
  }
}

const dialogLabel = computed(() => {
  if (category.value === 'history') {
    return t('planning.plannedMenu.addDishFromHistory', { plannedMenuName: props.plannedMenuName })
  } else if (category.value === 'favorites') {
    return t('planning.plannedMenu.addDishFromFavorites', {
      plannedMenuName: props.plannedMenuName,
    })
  } else if (category.value === 'scans') {
    return t('planning.plannedMenu.addDishFromScans', { plannedMenuName: props.plannedMenuName })
  } else if (category.value === 'planned') {
    return t('planning.plannedMenu.addDishFromPlanned', { plannedMenuName: props.plannedMenuName })
  } else if (category.value === 'tags') {
    return t('planning.plannedMenu.addDishFromTags', { plannedMenuName: props.plannedMenuName })
  } else {
    return t('planning.plannedMenu.addDish', { plannedMenuName: props.plannedMenuName })
  }
})
</script>
<template>
  <Sidebar v-model:visible="showSideBar" :header="dialogLabel" position="bottom" style="height: 90%"
    :block-scroll="true" class="w-full md:w-3/4 lg:w-1/2">
    <div class="flex items-center justify-start grow my-4">
      <Button v-if="category || showTagView" rounded link icon="pi pi-chevron-left" class="text-xl"
        :pt="{ root: { class: ' !w-8 !h-8' } }" :pt-options="{ mergeProps: true }" @click="goBack" />
    </div>
    <MyRecipesInDialogView v-if="!category && !showTagView" @select-category="selectCategory" />

    <DishTagsInDialog v-if="showTagView" @select-tag="selectTag" @select-category="selectCategory" />

    <GenerationHistoryView v-if="category === 'history'" @add-dish-to-plan="addDishToPlan" />
    <MyScansView v-if="category === 'scans'" @add-dish-to-plan="addDishToPlan" />
    <PlannedView v-if="category === 'planned'" @add-dish-to-plan="addDishToPlan" />
    <FavoritesView v-if="category === 'favorites'" @add-dish-to-plan="addDishToPlan" />
    <TagView v-if="category === 'tags' && subCategory" @add-dish-to-plan="addDishToPlan" :tagId="Number(subCategory)" />
    <GeneratedTitlesView v-if="category === 'titles'" @add-dish-title-to-plan="addDishTitleToPlan" />
  </Sidebar>
</template>
