import { useEnvStore } from '@/stores/envStore'
import { useFetch } from './useFetch'
import i18n from '@/i18n'
import type { Dish } from '@/types/dish'
import { usePlanningStore } from '@/stores/planningStore'
import { useDishesStore } from '@/stores/dishesStore'

const { fetchData } = useFetch()


const { t } = i18n.global

export const usePlanning = () => {
  async function planDish(dishId: number): Promise<boolean> {
    console.log("planning dish ") // TODO
    // const url = useEnvStore().apiUrl + '/planning/plan'
    // const { error } = await fetchData(url, 'DELETE')
    // if (error) {
    //   console.log(error)
    //   return false
    // }
    return true
  }

  async function duplicatePlannedDishAndUpdatePlan(dish: Dish, menuCourseId: number, plannedMenuId: number) {
    
    const url = useEnvStore().apiUrl + '/planning/planned-menus/' + plannedMenuId + '/menu-courses/' + menuCourseId;
    
    const { data, error } = await fetchData(url, 'POST', { 'dish_id' : dish.id })
    if (error || !data.dish) {
      console.log(error)
      return null
    }

    const planningStore = usePlanningStore();
    const dishesStore = useDishesStore();
    // const duplicationResponse: Dish | null = await duplicateDish(dish.id);
    const newDish = data.dish;
    // console.log(data.dish)
    if (dishesStore.selectedDish && dishesStore.selectedDish?.id === dish.id) {
      dishesStore.selectedDish = { ...newDish! };
    };

    if (planningStore.selectedPlannedMenu && planningStore.selectedPlannedMenu?.id === plannedMenuId) {
      planningStore.selectedPlannedMenu.menu_courses.find(menuCourse => menuCourse.id === menuCourseId)!.dish = { ...newDish! };
    }
    planningStore.shouldRefetch = true;
    dishesStore.shouldRefetch = true;

    planningStore.plannedMenus.find(plannedMenu => plannedMenu.id === plannedMenuId)!.menu_courses.find(menuCourse => menuCourse.id === menuCourseId)!.dish = { ...newDish! };
    return newDish;
  }

  return { planDish, duplicatePlannedDishAndUpdatePlan }
}
