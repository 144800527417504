<script setup lang="ts">
import { useDishesStore } from '@/stores/dishesStore'
import { computed, onMounted, onUnmounted, ref, watch, type ComputedRef, type Ref } from 'vue'
import type { Dish } from '@/types/dish'
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import { useFetch } from '@/composables/useFetch'
import { useEnvStore } from '@/stores/envStore'
import { useToast } from 'primevue/usetoast'
import { useRoute, useRouter } from 'vue-router'
import { usePlanningStore } from '@/stores/planningStore'
import PlannedMenuLine from '@/components/Planning/PlannedMenuLine.vue'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'
import InputText from 'primevue/inputtext'
import { debounce } from '@/utils/functions'
import html2canvas from 'html2canvas-pro'
import Dialog from 'primevue/dialog'
import ShareCard from '@/assets/icons/Planning/ShareCard.vue'
import DishImage from '@/components/Menus/DishImage.vue'
import type { MenuCourse } from '@/types/planning'
import Menu from 'primevue/menu'
import ButtonMenu from '@/assets/icons/Buttons/ButtonMenu.vue'
import { useConfirm } from 'primevue/useconfirm'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import NutritionalValuesCard from '@/components/Menus/MenuDishViewComponents/NutritionalValuesCard.vue'
import { useUserStore } from '@/stores/useUserStore'

const { t, locale } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const route = useRoute()
const router = useRouter()
const toast = useToast()
const envStore = useEnvStore()
const dishesStore = useDishesStore()
const dish = ref<Dish | null>(dishesStore.selectedDish)
dish.value = dishesStore.selectedDish
const { fetchData } = useFetch()
const confirmDialog = useConfirm()

const planningStore = usePlanningStore()
const { user } = useUserStore()
const shareModalVisible = ref(false)
const isImageModalVisible = ref(false)
const dishImageRef: Ref<InstanceType<typeof DishImage> | null> = ref(null)
const popupMenu = ref()
const mainDishImageSrc = ref('')

const isTitleTooLong = ref(false)
const MAX_TITLE_LENGTH = 20
const MAX_UPPERCASE_LENGTH = 14

const popUpMenuItems = ref([
  {
    items: [
      {
        label: t('planning.plannedMenu.delete.buttonLabel'),
        icon: 'pi pi-trash',
        command: () => confirmDeletePlannedMenu(),
      },
      // {
      //   label: t('menuDishView.topMenu.duplicate'),
      //   icon: 'pi pi-clone',
      //   command: () =>
      //   console.log("Duplicate"),
      //     // duplicateDish(props.dish.id).then((duplicationResponse) =>
      //     //   emit('handleDuplication', duplicationResponse)
      //     // ),
      // },
      // {
      //   label: t('menuDishView.topMenu.share'),
      //   icon: 'pi pi-share-alt',
      // },
    ],
  },
])

const plannedMenuTitle = ref(planningStore.selectedPlannedMenu?.name || '')

const plannedMenuPrice = ref(planningStore.selectedPlannedMenu?.price || 0);

const formattedPrice: ComputedRef<string> = computed(() => {
  const price = plannedMenuPrice.value;

  // Si le prix est un entier
  if (price % 1 === 0) {
    return `chf ${price}.-`;
  }
    return `chf ${price}`;
});


watch(
  () => planningStore.selectedPlannedMenu?.name,
  (newName) => {
    plannedMenuTitle.value = newName || ''
  }
)

watch(
  () => planningStore.selectedPlannedMenu?.price,
  (newPrice) => {
    plannedMenuPrice.value = newPrice || 0;
  }
)
const temporaryMenuCourses = ref<MenuCourse[]>([])

// When dishToPlanClipboard is not null, prepare to add the dish to the temporary list
watch(
  () => planningStore.dishToPlan?.clipboardDish,
  (newDish) => {
    if (newDish) {
      // Add the dish to the first available course (e.g., entree if it's empty)
      const emptyCourse = temporaryMenuCourses.value.find((course) => !course.dish)
      if (emptyCourse) {
        emptyCourse.dish = newDish // Assign the dish to the empty course
      } else {
        // Or push it into a new course
        temporaryMenuCourses.value.push({
          id: -1, // Temporary ID
          name: newDish.name,
          order: 2, // Example: main dish
          dish: newDish,
          planned_menu_id: planningStore.selectedPlannedMenu?.id || -1,
        })
      }
    }
  }
)

const updatePlannedMenuTitle = async () => {
  if (planningStore.selectedPlannedMenu) {
    planningStore.selectedPlannedMenu.name = plannedMenuTitle.value
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu)
  }
}

const updatePlannedMenuPrice = async () => {
  if (planningStore.selectedPlannedMenu) {
    // const parsedPrice = parseFloat(tempPrice.value);
    // Ensure the price is not negative and is a valid number
    // const validPrice = !isNaN(parsedPrice) ? Math.max(0, parsedPrice) : 0;
    // plannedMenuPrice.value = validPrice;
    planningStore.selectedPlannedMenu.price = plannedMenuPrice.value;
    await planningStore.updatePlannedMenu(planningStore.selectedPlannedMenu);
  }
}


// Create a debounced version of the updatePlannedMenuTitle function
const debouncedUpdatePlannedMenuTitle = debounce(updatePlannedMenuTitle, 500)
const debouncedUpdatePlannedMenuPrice = debounce(updatePlannedMenuPrice, 750);

// mounted
onMounted(async () => {
  // console.log("mounted PlannedMenuView");
  if (
    !planningStore.selectedPlannedMenu ||
    (planningStore.selectedPlannedMenu.id.toString() !== route.params.id &&
      !planningStore.dishToPlan)
  ) {
    // console.log("no selectedPlannedMenu, get the plannedMenu from route");
    planningStore.selectPlannedMenu(parseInt(route.params.id.toString()))
  }
})

onUnmounted(() => {
  // console.log("unMount Planned Menu, set selectedPlannedMenu to null")
  planningStore.selectedPlannedMenu = null
})

const formattedDate = computed(() => {
  if (!planningStore.selectedPlannedMenu?.date) return ''

  const date = new Date(planningStore.selectedPlannedMenu.date)
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long', // 'long' format for the day
    month: 'long', // 'long format of month'
    day: '2-digit', // two-digit day
    year: 'numeric', // four-digit year
  }

  // Formatting the date according to locale
  let dateString = date.toLocaleDateString(locale.value, options)

  // Capitalizing the first letter of the weekday and adjusting format
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1)
  dateString = dateString.replace(',', '') // Remove any commas

  // Remove the period if it exists at the end
  if (dateString.endsWith('.')) {
    dateString = dateString.slice(0, -1)
  }

  return dateString
})

const sortedAndFilledCourses: ComputedRef<(MenuCourse | null)[]> = computed(() => {
  const courses = planningStore.selectedPlannedMenu?.menu_courses || []
  const sortedCourses = [...courses].sort((a, b) => a.order - b.order)

  // Ensure all three course types are present
  const courseTypes = [1, 2, 3] // Corresponds to Entrée, Plat principal, Dessert
  return courseTypes.map((type) => {
    const found = sortedCourses.find((course) => course.order === type)
    return found || null
  })
})

function openShareModal() {
  shareModalVisible.value = true
}

const mainDish = computed(() => {
  const dish = planningStore.selectedPlannedMenu?.menu_courses.find(
    (course) => course.order === 2
  )?.dish
  if (!dish) return null

  // Check if any image URLs are available
  const imageUrl = dish.image_url_gastro || dish.image_url_bistro || dish.image_url_user
  return {
    ...dish,
    imageUrl: imageUrl || '',
  }
})

const imageDataUrl = ref('') // Reactive property to hold the image data URL

const shareMenu = () => {
  shareMenuImage('storyMainDish');
  // const element = document.getElementById('share-story-main')
  // if (!element) {
  //   console.error('Element not found')
  //   return
  // }
  // element.style.display = 'block' // Unhide the element for capturing

  // html2canvas(element, { scale: 1, useCORS: true })
  //   .then((canvas) => {
  //     element.style.display = 'none' // Hide the element again
  //     imageDataUrl.value = canvas.toDataURL('image/png') // Store the image data URL
  //     openShareModal() // Open the modal to show the image
  //   })
  //   .catch((error) => {
  //     console.error('Error capturing the canvas:', error)
  //     element.style.display = 'none' // Ensure the element is hidden if there's an error
  //   })
}

function addOrUpdateMenuCourse(menuCourse: MenuCourse) {
  if (!planningStore.selectedPlannedMenu) return
  menuCourse.planned_menu_id = planningStore.selectedPlannedMenu.id
  planningStore.addOrUpdateMenuCourse(menuCourse, router)
}

function getOrderedIndex(index: number): 1 | 2 | 3 {
  return (((index - 1) % 3) + 1) as 1 | 2 | 3
}

function togglePopUpMenu(event: MouseEvent) {
  popupMenu.value.toggle(event)
}

const confirmDeletePlannedMenu = () => {
  confirmDialog.require({
    message: t('planning.plannedMenu.delete.message'),
    header: t('planning.plannedMenu.delete.title'),
    icon: 'pi pi-exclamation-triangle',
    rejectClass: 'p-button-secondary p-button-outlined',
    rejectLabel: t('planning.plannedMenu.delete.cancel'),
    acceptLabel: t('planning.plannedMenu.delete.confirm'),
    accept: () => {
      if (planningStore.selectedPlannedMenu)
        planningStore.deletePlannedMenu(planningStore.selectedPlannedMenu.id, router)
    },
    reject: () => {},
  })
}

// Call fetchMainDishImage when mainDish changes

const fetchMainDishImage = async () => {
  if (mainDish.value && mainDish.value.id && mainDish.value.selected_image) {
    const { data, error } = await fetchData(
      `${envStore.apiUrl}/dishes/${mainDish.value.id}/images/${mainDish.value.selected_image}`
    )

    if (error) {
      console.error('Error fetching image:', error)
      // Handle error (e.g., set a default image or show an error message)
    } else if (data && data.image) {
      mainDishImageSrc.value = data.image
    }
  }
}

watch(mainDish, fetchMainDishImage, { immediate: true })

const countUpperCase = (str: string) => {
  return str.split('').filter((char) => char === char.toUpperCase() && char !== char.toLowerCase())
    .length
}

const titleLengthError = computed(() => {
  const uppercaseCount = countUpperCase(plannedMenuTitle.value)
  if (plannedMenuTitle.value.length > MAX_TITLE_LENGTH || uppercaseCount > MAX_UPPERCASE_LENGTH) {
    return t('planning.plannedMenu.titleTooLongWarning')
  }
  return ''
})

const checkTitleLength = () => {
  const uppercaseCount = countUpperCase(plannedMenuTitle.value)
  isTitleTooLong.value =
    plannedMenuTitle.value.length > MAX_TITLE_LENGTH || uppercaseCount > MAX_UPPERCASE_LENGTH
}

const formattedUrl = (url) => {
  return url.replace(/^https?:\/\//, '')
}

watch(plannedMenuTitle, checkTitleLength)

const shareFormats = [
  {
    name: 'storyMainDish',
    id: 'share-story-main',
    width: 1080,
    height: 1920,
    description: 'Story image with main dish and image',
  },
  {
    name: 'storyFull',
    id: 'share-story-full',
    width: 1080,
    height: 1920,
    description: 'Story image with all dishes and image',
  },
  {
    name: 'storyWNutri',
    id: 'share-story-nutri',
    width: 1080,
    height: 1920,
    description: 'Story image with main dish and nutritional facts',
  },
];

function shareMenuImage(formatName: string) {
  const selectedFormat = shareFormats.find((format) => format.name === formatName);
  if (!selectedFormat) {
    console.error('Format non trouvé');
    return;
  }

  const element = document.getElementById(selectedFormat.id);
  if (!element) {
    console.error('Élément non trouvé pour le format', formatName);
    return;
  }

  element.style.display = 'block'; // Unhide the element for capturing

  html2canvas(element, { scale: 1, useCORS: true, width: selectedFormat.width, height: selectedFormat.height })
    .then((canvas) => {
      element.style.display = 'none'; // Hide the element again
      imageDataUrl.value = canvas.toDataURL('image/png'); // Store the image data URL
      openShareModal(); // Open the modal to show the image
    })
    .catch((error) => {
      console.error('Erreur lors de la capture du canvas:', error);
      element.style.display = 'none'; // Ensure the element is hidden if there's an error
    });
}

</script>

<template>
  <main>
    <Dialog v-if="mainDish" v-model:visible="isImageModalVisible" modal style="max-width: 90%" class="sm:max-w-3xl">
      <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
        ref="dishImageRef" size="full" />
    </Dialog>
    <Menu ref="popupMenu" :model="popUpMenuItems" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
      :pt-options="{ mergeProps: true }" />
    <div class="flex flex-col gap-6">
      <div class="flex justify-end gap-6 items-top" v-if="!planningStore.dishToPlan">
        <Button v-if="mainDish && mainDish.imageUrl" rounded link :pt="{ root: { class: '!p-1.5 !w-8 !h-8' } }"
          :pt-options="{ mergeProps: true }" @click="shareMenu">
          <template #icon>
            <ButtonShare />
          </template>
        </Button>
        <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
          @click="togglePopUpMenu">
          <template #icon>
            <ButtonMenu />
          </template>
        </Button>
      </div>
      <!-- <div>
      Header with info
    </div> -->
      <div v-if="mainDish && mainDish.imageUrl" class="flex justify-center my-5">
        <DishImage v-if="mainDish" :dishId="mainDish?.id" :type="mainDish?.selected_image" :alt="mainDish?.name"
          ref="dishImageRef" @click="isImageModalVisible = true" class="cursor-pointer" />
      </div>
      <!-- <Button link rounded :pt="{ root: { class: '!p-1.5 !w-8 !h-8 rotate-90' } }" :pt-options="{ mergeProps: true }"
        @click="togglePopUpMenu">
        <template #icon>
          <ButtonMenu />
        </template>
      </Button>
      <Menu ref="popupMenu" id="overlay_menu" :model="items" :popup="true" :pt="{ submenuHeader: { class: '!p-0' } }"
        :pt-options="{ mergeProps: true }" /> -->
      <div>
        <p class="text-lg text-center">{{ formattedDate }}</p>
      </div>

      <!-- <p class="text-lg text-center">{{ planningStore.selectedPlannedMenu?.name }}</p> -->
      <div class="flex flex-col gap-4">
        <div class="grid items-center grid-cols-12 gap-2">
          <div class="col-span-2 min-w-4">
            <p>
              {{ t('planning.plannedMenu.titleLabel') }}
            </p>
          </div>
          <div class="flex justify-center col-span-9 min-w-52">
            <div class="flex flex-col w-full">
              <InputText id="plannedMenuTitle" v-model="plannedMenuTitle" @input="debouncedUpdatePlannedMenuTitle"
                class="w-full" />
              <small v-if="titleLengthError" class="text-xs text-accent">
                {{ titleLengthError }}
              </small>
            </div>
          </div>
        </div>
        <div class="grid items-center grid-cols-12 gap-2">
          <div class="col-span-2 min-w-4">
            <p>
              {{ t('planning.plannedMenu.priceLabel') }}
            </p>
          </div>
          <div class="flex justify-center col-span-9 min-w-52">
            <div class="flex flex-col w-full">
              <InputNumber input-id="plannedMenuPrice" v-model="plannedMenuPrice"
                @input="debouncedUpdatePlannedMenuPrice" :min="0" :step="0.01" mode="currency" currency="CHF" />
            </div>
          </div>
        </div>
      </div>

      <div v-for="(menuCourse, order) in sortedAndFilledCourses" :key="menuCourse?.id">
        <PlannedMenuLine :order="getOrderedIndex(order + 1)" :menu-course="menuCourse"
          @add-or-update-menu-course="addOrUpdateMenuCourse" @delete-menu-course="planningStore.deleteMenuCourse" />
        <!-- <div v-else class="flex justify-center p-6 text-center" >
          <button @click="console.log('add course')"
            class="flex items-center justify-center w-10 p-2 text-white rounded-full bg-accent aspect-square">
            
            <i class="pi pi-plus" style="font-size: 1rem"></i>
          </button>
        </div> -->
      </div>
    </div>

    <Dialog v-if="sortedAndFilledCourses" v-model:visible="shareModalVisible" modal style="max-width: 90%"
      class="sm:max-w-3xl">
      <ShareCard :image="imageDataUrl" />
    </Dialog>
    <!-- <div id="share-item" class="menu-card w-[1080px] h-[1080px] flex flex-col justify-between"> -->
    <div id="share-story-main"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div class="bg-[#f09f26] h-[180px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="text-7xl text-white line-clamp-1 leading-none">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-white">{{ formattedDate }}</h2>
      </div>
      <div class="w-[900px] mx-auto mt-24">
        <div class="relative w-full aspect-square mx-auto  rounded-3xl mb-20">
          <img src="@/assets/images/avatar-head-transparent.svg" class="absolute -top-16 -right-7 w-32 object-cover" />
          <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-cover w-full h-full rounded-3xl" />

        </div>
        <div class="flex max-w-[900px] h-[430px] mx-auto flex-col items-start justify-center gap-11 self-stretch mb-20">
          <p class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ mainDish?.name }}
          </p>
          <div v-if="plannedMenuPrice > 0" class="h-0.5 w-14 bg-gray-300 my-2 mx-auto">
          </div>
          <p v-if="plannedMenuPrice > 0" class="font-garamond text-center text-6xl italic font-bold w-full">{{
            formattedPrice }}</p>
        </div>
        <footer class="absolute left-0 flex flex-row items-center justify-center w-full mx-auto gap-7 bottom-16">
          <div v-if="user?.restaurants[0]?.logo" class="w-28 h-28">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo" alt="Logo restaurant"
              class="object-contain w-full h-full p-1" />
          </div>
          <div class="flex flex-col items-start text-2xl text-left shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ user?.restaurants[0]?.name }}</p>
            <p v-if="user?.restaurants[0]?.phone_number">
              {{ user?.restaurants[0]?.phone_number }}
            </p>
            <p v-if="user?.restaurants[0]?.website_url">
              {{ formattedUrl(user?.restaurants[0]?.website_url) }}
            </p>
          </div>
          <!-- <p class="w-full text-left ">Gastronomia</p> -->
        </footer>
      </div>

      <!-- class="menu-card w-[1080px] h-[1920px] flex flex-col justify-between hidden absolute -left-[5000px] top-0"> -->
      <!-- <div class=""> -->
    </div>
    <div id="share-story-full"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div class="bg-[#f09f26] h-[180px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="text-7xl text-white line-clamp-1 leading-none">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-white">{{ formattedDate }}</h2>
      </div>
      <div class="w-[900px] mx-auto mt-24">
        <div class="relative w-full aspect-square mx-auto  rounded-3xl mb-20">
          <img src="@/assets/images/avatar-head-transparent.svg" class="absolute -top-16 -right-7 w-32 object-cover" />
          <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-cover w-full h-full rounded-3xl" />

        </div>
        <div class="flex max-w-[900px] h-[430px] mx-auto flex-col items-start justify-center gap-11 self-stretch mb-20">
          <p class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ mainDish?.name }}
          </p>
          <div v-if="plannedMenuPrice > 0" class="h-0.5 w-14 bg-gray-300 my-2 mx-auto">
          </div>
          <p v-if="plannedMenuPrice > 0" class="font-garamond text-center text-6xl italic font-bold w-full">{{
            formattedPrice }}</p>
        </div>
        <footer class="absolute left-0 flex flex-row items-center justify-center w-full mx-auto gap-7 bottom-16">
          <div v-if="user?.restaurants[0]?.logo" class="w-28 h-28">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo" alt="Logo restaurant"
              class="object-contain w-full h-full p-1" />
          </div>
          <div class="flex flex-col items-start text-2xl text-left shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ user?.restaurants[0]?.name }}</p>
            <p v-if="user?.restaurants[0]?.phone_number">
              {{ user?.restaurants[0]?.phone_number }}
            </p>
            <p v-if="user?.restaurants[0]?.website_url">
              {{ formattedUrl(user?.restaurants[0]?.website_url) }}
            </p>
          </div>
          <!-- <p class="w-full text-left ">Gastronomia</p> -->
        </footer>
      </div>

      <!-- class="menu-card w-[1080px] h-[1920px] flex flex-col justify-between hidden absolute -left-[5000px] top-0"> -->
      <!-- <div class=""> -->
    </div>
    <div id="share-story-nutri"
      class="relative menu-card w-[1080px] h-[1920px] border-2 border-slay-200 hidden absolute -left-[5000px] top-0">
      <div class="bg-[#f09f26] h-[180px] px-24 w-[1080px] flex flex-col justify-center">
        <h1 class="text-7xl text-white line-clamp-1 leading-none">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-white">{{ formattedDate }}</h2>
      </div>
      <div class="w-[900px] mx-auto mt-24">
        <div class="relative w-[400px] aspect-square mx-auto  rounded-3xl mb-15">
          <img src="@/assets/images/avatar-head-transparent.svg" class="absolute -top-16 -right-7 w-32 object-cover" />
          <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-cover w-full h-full rounded-3xl" />

        </div>
        <div class="flex max-w-[900px] h-[430px] mx-auto flex-col items-start justify-center gap-11 self-stretch mb-20">
          <p class="w-11/12 mx-auto text-6xl italic font-bold leading-normal text-center font-garamond line-clamp-3">
            {{ mainDish?.name }}
          </p>
          <div v-if="plannedMenuPrice > 0" class="h-0.5 w-14 bg-gray-300 my-2 mx-auto">
          </div>
          <p v-if="plannedMenuPrice > 0" class="font-garamond text-center text-6xl italic font-bold w-full">{{
            formattedPrice }}</p>
        </div>
        <div>
          <NutritionalValuesCard :dish="mainDish" />
        </div>
        <footer class="absolute left-0 flex flex-row items-center justify-center w-full mx-auto gap-7 bottom-16">
          <div v-if="user?.restaurants[0]?.logo" class="w-28 h-28">
            <img v-if="user?.restaurants[0]?.logo" :src="user?.restaurants[0]?.logo" alt="Logo restaurant"
              class="object-contain w-full h-full p-1" />
          </div>
          <div class="flex flex-col items-start text-2xl text-left shrink-0">
            <p class="w-full text-4xl italic font-garamond">{{ user?.restaurants[0]?.name }}</p>
            <p v-if="user?.restaurants[0]?.phone_number">
              {{ user?.restaurants[0]?.phone_number }}
            </p>
            <p v-if="user?.restaurants[0]?.website_url">
              {{ formattedUrl(user?.restaurants[0]?.website_url) }}
            </p>
          </div>
          <!-- <p class="w-full text-left ">Gastronomia</p> -->
        </footer>
      </div>

      <!-- class="menu-card w-[1080px] h-[1920px] flex flex-col justify-between hidden absolute -left-[5000px] top-0"> -->
      <!-- <div class=""> -->
    </div>
    <div id="share-item-2"
      class="hidden menu-card w-[1080px] h-[1080px] flex flex-col justify-between hidden absolute -left-[5000px] top-0">
      <div class="bg-[#f09f26] py-14 px-16">
        <h1 class="leading-tight text-white text-7xl line-clamp-1">{{ plannedMenuTitle }}</h1>
        <h2 class="text-4xl text-white">{{ formattedDate }}</h2>
      </div>

      <!-- <ul class="text-center text-4xl flex flex-col justify-between max-h-[700px] px-16"> -->
      <ul :class="[
          'text-center flex flex-col justify-between px-28 py-4',
          mainDish && mainDish.imageUrl ? 'text-3xl h-[400px]' : 'text-4xl h-[700px]',
        ]">
        <!-- <li v-for="(menuCourse, index) in sortedAndFilledCourses" :key="menuCourse?.id"
        class="flex flex-col justify-around flex-grow"> -->
        <li v-for="(menuCourse, index) in sortedAndFilledCourses" :key="menuCourse?.id"
          class="flex flex-col justify-around flex-grow">
          <p class="leading-tight" :class="[mainDish && mainDish.imageUrl ? 'line-clamp-2' : 'line-clamp-3']">
            {{ menuCourse?.dish?.name ?? menuCourse?.name ?? '***' }}
          </p>
          <!-- Show the divider line only if it's not the last item in the list -->
          <div v-if="index < sortedAndFilledCourses.length - 1" class="h-0.5 w-10 bg-gray-300 my-2 mx-auto"></div>
        </li>
      </ul>
      <div v-if="mainDish && mainDish.imageUrl" class="flex flex-1 justify-center my-5 h-[360px] max-w-[800px] mx-auto">
        <img :src="mainDishImageSrc" :alt="mainDish?.name" class="object-contain w-full h-full rounded-3xl" />
      </div>
      <footer class="flex flex-row items-center mx-auto mb-7 gap-14">
        <!-- <div class="w-24" v-if="">
          Image
        </div> -->
        <div class="text-2xl text-left">
          <p class="font-bold">{{ user?.restaurants[0]?.name }}</p>
          <!-- <p>+41 123 45 67</p>
          <p>restaurant-le-soleil.ch</p> -->
        </div>
        <!-- <p class="w-full text-left">Gastronomia</p> -->
      </footer>
    </div>
  </main>
</template>

<style scoped></style>
