<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter()


import { withDefaults } from 'vue'

const props = withDefaults(defineProps<{
  icon: any
  text: string
  navigate: string
  disabled?: boolean
  small?: boolean
}>(), {
  disabled: false,
  small: false
})

function redirectUser() {
  const [path, queryString] = props.navigate.split('?');
  const queryParams = new URLSearchParams(queryString);
  const type = queryParams.get('type');
  router.push({
    path: path,
    query: type ? { type: type } : undefined // Only add query parameters if 'type' exists
  });
  // console.log('Navigate to:', props.navigate); // Add this line to check the value
  // router.push({ path: props.navigate });
}
</script>

<template>
  <!-- <div class="flex flex-col gap-3 items-center text-center align-bottom w-24 justify-between hover:cursor-pointer"
    :class="{ 'opacity-60 pointer-events-none': disabled }" @click="props.disabled ? undefined : redirectUser()">
    <component :is="icon" />
    <h3 class="text-xs">{{ text }}</h3>
  </div> -->

  <div :class="[
    'p-2 bg-white rounded-xl shadow-md flex-col justify-center items-center gap-3 inline-flex',
    props.small ? 'w-16 h-16' : 'w-36 h-36',
    { 'opacity-60 pointer-events-none': disabled }
  ]" @click="props.disabled ? undefined : redirectUser()">
    <div :class="[
      'rounded-3xl justify-start items-center gap-4 inline-flex',
      props.small ? '' : 'p-4'
    ]">
      <component :is="icon" :class="[
        'text-primary-950',
        props.small ? 'w-6 h-6' : 'w-9 h-9'
      ]" />
    </div>
    <div :class="['text-center', props.small ? '' : '']">
      <h2 :class="props.small ? 'text-[9px] leading-none' : 'text-lg'">{{ text }}</h2>
    </div>
  </div>
</template>
