<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { setLocale, type MessageSchema } from '@/i18n/index.js'
import InputText from 'primevue/inputtext'
import { ref, type Ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { User } from '@/types/api.js'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { useProfileProfile } from '@/composables/useProfileProfile.js'
import { useAuth } from '@/composables/useAuth'
import { useAppStore } from '@/stores/appStore'
import { watch } from 'vue'
import { useUserStore } from '@/stores/useUserStore'

const user = ref(useUserStore().user)

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const toast = useToast()
const { logout, isFetching } = useAuth()

const { updateUserProperty } = useProfileProfile(toast)
const appStore = useAppStore();

// const languagesList = ref(['french', 'english', 'german', 'italian'])
const languagesList = ref<{ label: string; value: 'fr' | 'en' | 'de' | 'it' }[]>([
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
  { label: 'Deutsch', value: 'de' },
  { label: 'Italiano', value: 'it' },
])


const firstName = ref(user.value?.first_name || '')
const lastName = ref(user.value?.last_name || '')
const email = ref(user.value?.email || '')
const selectedLanguage = ref(
  languagesList.value.find((language) => language.value === appStore.language) || languagesList.value[0]
)

async function updateProperty(propertyName: string, value: string | number) {
  if (!user.value) return // Make sure the user is defined

  const updateResponse = await updateUserProperty(propertyName, value)
  if (updateResponse !== null) {
    // Update the local user state
    user.value = updateResponse
    // Also update the reactive references for form fields
    if (propertyName === 'first_name') firstName.value = updateResponse.first_name
    if (propertyName === 'last_name') lastName.value = updateResponse.last_name
    if (propertyName === 'email') email.value = updateResponse.email
  }
}

function updateLanguage() {
  const newLanguage = selectedLanguage.value.value

  appStore.setLanguage(newLanguage)
  updateProperty('language', newLanguage)
}

// Watch for changes in the language from the store and update the i18n locale accordingly
watch(
  () => appStore.language as 'fr' | 'en' | 'de' | 'it',
  (newLanguage) => {
    setLocale(newLanguage)
  }
)
</script>

<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3>{{ t('profile.profile.profileTab.personnalData.title') }}</h3>
      <h4 class="mt-2">{{ t('profile.profile.profileTab.personnalData.firstName') }}</h4>
      <InputText class="w-full" v-model="firstName" @blur="updateProperty('first_name', firstName)" />
      <h4 class="mt-2">{{ t('profile.profile.profileTab.personnalData.lastName') }}</h4>
      <InputText class="w-full" v-model="lastName" @blur="updateProperty('last_name', lastName)" />
    </div>

    <div>
      <h3>{{ t('profile.profile.profileTab.language.title') }}</h3>
      <Dropdown class="w-1/2" :options="languagesList" optionLabel="label" v-model="selectedLanguage"
        @change="updateLanguage">
        <template #value="slotProps">
          {{ slotProps.value.label }}
        </template>
        <template #option="slotProps">
          {{ slotProps.option.label }}
        </template>
      </Dropdown>
    </div>

    <div>
      <h3>{{ t('profile.profile.profileTab.passwordAndEmail.title') }}</h3>
      <h4 class="mt-2">{{ t('profile.profile.profileTab.passwordAndEmail.email') }}</h4>
      <p>{{ email }}</p>
      <!-- <InputText class="w-full" v-model="email" @blur="updateProperty('email', email)" disabled /> -->
      <h4 class="mt-2">{{ t('profile.profile.profileTab.passwordAndEmail.password') }}</h4>
      <Button class="mt-1" disabled>
        {{ t('profile.profile.profileTab.passwordAndEmail.changePassword') }}
      </Button>
    </div>
    <Button :label="t('auth.logout')" class="w-full mt-8" @click="logout" :loading="isFetching" />

  </div>
</template>
