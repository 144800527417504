<script setup lang="ts">
import HeartEmpty from '@/assets/icons/2States/HeartEmpty.vue'
import HeartFull from '@/assets/icons/2States/HeartFull.vue'
import ButtonEdit from '@/assets/icons/Buttons/ButtonEdit.vue'
import FaxIcon from '@/assets/icons/Other/FaxIcon.vue'
import LinkIcon from '@/assets/icons/Other/LinkIcon.vue'
import EmailIcon from '@/assets/icons/Suppliers/EmailIcon.vue'
import LocationIcon from '@/assets/icons/Suppliers/LocationIcon.vue'
import PhoneIcon from '@/assets/icons/Suppliers/PhoneIcon.vue'
import { useFetch } from '@/composables/useFetch.js'
import type { MessageSchema } from '@/i18n/index.js'
import { useEnvStore } from '@/stores/envStore.js'
import type { Supplier } from '@/types/api.js'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import { onMounted, ref, type Ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import LoadingView from '../LoadingView.vue'
import Tag from 'primevue/tag'
import { useConfirm } from 'primevue/useconfirm'
import { useUserStore } from '@/stores/useUserStore'

const toast = useToast()
const user = ref(useUserStore().user)
const envStore = useEnvStore()
const { fetchData } = useFetch()
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const router = useRouter()
const confirm = useConfirm()

const supplier: Ref<Supplier | null> = ref(null)
const isFavorite: Ref<boolean> = ref(false)

const confirmDeletion = (event) => {
  confirm.require({
    target: event.currentTarget,
    message: t('profile.suppliers.deleteConfirmation'),
    icon: 'pi pi-exclamation-triangle',
    rejectLabel: t('common.cancel'),
    acceptLabel: t('common.delete'),
    accept: () => {
      deleteSupplier()
    },
  })
}

async function toggleFavorite(supplierUuid: string) {
  if (!user.value) return
  const restaurantId = user.value.restaurants[0].id
  fetchData(
    `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers/${supplierUuid}/toggle-favorite`,
    'PATCH'
  ).then((response) => {
    if (response.data) {
      let toastText = ''
      if (response.data.status === 'added') {
        isFavorite.value = true
        toastText = t('profile.suppliers.favoriteSupplierAdded')
      } else {
        isFavorite.value = false
        toastText = t('profile.suppliers.favoriteSupplierRemoved')
      }
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: toastText,
        life: 3000,
      })
    } else {
      console.log(response.error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('profile.suppliers.toogleFavoriteError'),
        life: 3000,
      })
    }
  })
}

async function deleteSupplier() {
  if (!user.value) return
  const restaurantId = user.value.restaurants[0].id
  fetchData(
    `${useEnvStore().apiUrl}/restaurants/${restaurantId}/suppliers/${supplier.value?.uuid}`,
    'DELETE'
  ).then((response) => {
    if (response.data) {
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('profile.suppliers.deleteSuccess'),
        life: 3000,
      })
      router.replace({ name: 'suppliers' })
    } else if (response.error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('profile.suppliers.deleteError'),
        life: 3000,
      })
    }
  })
}

onMounted(async () => {
  const route = useRoute()
  fetchData(`${envStore.apiUrl}/suppliers/${route.params.uuid}`).then((response) => {
    if (response.data) {
      supplier.value = response.data as Supplier
    } else if (response.error) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('profile.suppliers.getSuppliersError'),
        life: 3000,
      })
    }
  })

  fetchData(`${envStore.apiUrl}/restaurants/${user.value?.restaurants[0].id}/suppliers`).then(
    (response) => {
      if (response.data) {
        if (response.data.find((supplier: Supplier) => supplier.uuid === route.params.uuid)) {
          isFavorite.value = true
        }
      } else if (response.error) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('profile.suppliers.getSuppliersError'),
          life: 3000,
        })
      }
    }
  )
})
</script>

<template>
  <main>
    <div v-if="supplier">
      <h2 :class="supplier.tags.length > 0 ? 'mb-2' : 'mb-4'">{{ supplier.name }}</h2>

      <div class="flex gap-2 mb-6 text-sm text-primary-800" v-if="supplier.tags.length > 0">
        <Tag v-for="tag in supplier.tags" :key="tag.id">
          {{ t('profile.suppliers.tags.' + tag.name) }}
        </Tag>
      </div>

      <div class="space-y-8">
        <div class="space-y-4">
          <div class="flex gap-4" v-if="supplier.address">
            <span class="block w-6 aspect-square shrink-0 text-primary-600"><LocationIcon /></span>
            <span>
              {{ supplier.address.address_line_1 }},
              {{ supplier.address.city.postal_code }}
              {{ supplier.address.city.name }}
            </span>
          </div>
          <div class="flex gap-4" v-if="supplier.website">
            <span class="block w-6 aspect-square shrink-0 text-primary-600"><LinkIcon /></span>
            <a :href="supplier.website || '#'" target="_blank" class="hover:text-primary-600">
              {{ supplier.website }}
            </a>
          </div>
          <div class="flex gap-4" v-if="supplier.phone || supplier.phone_2 || supplier.phone_3">
            <span class="block w-6 aspect-square shrink-0 text-primary-600"><PhoneIcon /></span>
            <span>
              <!-- filter(Boolean) permet de retirer les valeurs falsy (comme null, undefined ou des chaînes vides)  -->
              {{ [supplier.phone, supplier.phone_2, supplier.phone_3].filter(Boolean).join(', ') }}
            </span>
          </div>

          <div class="flex gap-4" v-if="supplier.fax">
            <span class="block w-6 aspect-square shrink-0 text-primary-600"><FaxIcon /></span>
            <span>
              {{ supplier.fax }}
            </span>
          </div>
          <div class="flex gap-4" v-if="supplier.email">
            <span class="block w-6 aspect-square shrink-0 text-primary-600"><EmailIcon /></span>
            <span>
              {{ [supplier.email, supplier.email_2].filter(Boolean).join(', ') }}
            </span>
          </div>
        </div>

        <div v-if="supplier.logo_url" class="flex justify-center">
          <img
            :src="supplier.logo_url"
            alt="{{ supplier.name }} logo"
            class="object-contain max-w-full max-h-64"
          />
        </div>

        <Button @click="toggleFavorite(supplier.uuid)" class="w-full" v-if="!supplier.is_custom">
          <div class="w-4 mr-2 aspect-square">
            <HeartFull v-if="isFavorite" />
            <HeartEmpty v-else />
          </div>

          <span v-if="isFavorite">Retirer des favoris</span>
          <span v-else>Ajouter aux favoris</span>
        </Button>

        <div class="grid grid-cols-2 gap-2" v-else>
          <Button @click="router.push(`/suppliers/${supplier.uuid}/edit`)" class="w-full">
            <div class="w-4 mr-2 aspect-square">
              <ButtonEdit />
            </div>
            <span>{{ t('common.edit') }}</span>
          </Button>
          <Button class="w-full" severity="danger" @click="confirmDeletion">
            <div class="w-4 mr-2 aspect-square"><i class="pi pi-trash" /></div>
            <span>{{ t('common.delete') }}</span>
          </Button>
        </div>
      </div>
    </div>
    <div v-else>
      <LoadingView />
    </div>
  </main>
</template>
