import i18n from '@/i18n'
import type { Dish } from '@/types/dish'
import type { ToastServiceMethods } from 'primevue/toastservice'
import { ref, type Ref } from 'vue'

const { t } = i18n.global

const dishes: Ref<Dish[]> = ref([])
const dishTitles: Ref<Dish[]> = ref([]);

export const useProfileDishLists = (toast: ToastServiceMethods) => {
  function handleDuplication(duplicationResponse: null | Dish, originalId: number) {
    if (duplicationResponse === null) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('dishOperations.dishDuplicationError'),
        life: 3000,
      })
    } else {
      const originalDishIndex = dishes.value.findIndex((dish) => dish.id === originalId)

      dishes.value.splice(originalDishIndex, 0, duplicationResponse)

      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('dishOperations.dishDuplicationSuccess'),
        life: 3000,
      })
    }
  }

  function handleFavoriteChange(
    favoriteResponse: boolean | null,
    dishId: number,
    removeFromList?: boolean
  ) {
    if (favoriteResponse === null) {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('dishOperations.dishFavoriteError'),
        life: 3000,
      })
    } else {
      const dishIndex = dishes.value.findIndex((dish) => dish.id === dishId)
      dishes.value[dishIndex].is_favorite = favoriteResponse
      if (removeFromList && favoriteResponse === false) {
        dishes.value.splice(dishIndex, 1)
      }
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: favoriteResponse
          ? t('dishOperations.dishFavoriteAdded')
          : t('dishOperations.dishFavoriteRemoved'),
        life: 3000,
      })
    }
  }

  function handleDeletion(wasDeleted: boolean, deletedId: number) {
    if (wasDeleted) {
      dishes.value = dishes.value.filter((dish) => dish.id !== deletedId)
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: t('dishOperations.dishDeletionSuccess'),
        life: 3000,
      })
    } else {
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('dishOperations.dishDeletionError'),
        life: 3000,
      })
    }
  }

  return { handleDuplication, handleDeletion, handleFavoriteChange, dishes, dishTitles }
}
