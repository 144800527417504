<script setup lang="ts">
import SelectIngredientsList from '@/components/Menus/SelectIngredientsList.vue'

import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { ref, markRaw, onMounted, type Ref, computed } from 'vue'
import { useDishesStore } from '@/stores/dishesStore'
import { useFetch } from '@/composables/useFetch'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import type { Dish, Ingredient } from '@/types/dish'
import { adjustUnitsForQuantity, calculateDishPrice } from '@/utils/prices'
import Button from 'primevue/button'
import ButtonWithIconWrapper from '@/components/BaseComponents/ButtonWithIconWrapper.vue'
import NavPlanningIcon from '@/assets/icons/NavBar/NavPlanningIcon.vue'
import { useOrderList } from '@/stores/orderStore'
import router from '@/router'
import { useEnvStore } from '@/stores/envStore'
import LoadingView from '../LoadingView.vue'
import { useConfirm } from 'primevue/useconfirm'
import InlineLink from '@/components/InlineLink.vue'
import { watch } from 'vue'
import { transformAndSortIngredients } from '@/utils/dishes'
import Checkbox from 'primevue/checkbox'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import { useUserStore } from '@/stores/useUserStore'

const { t, te } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const dishesStore = useDishesStore()
const orderListStore = useOrderList()
const { isFetching, fetchData } = useFetch()
const route = useRoute()
const toast = useToast()
const isLoading = ref(orderListStore.isLoading)
const isDeleting = ref(false)
const shoppingListIngredients = ref<Ingredient[]>([])

const dish = ref<Dish | null>(dishesStore.selectedDish)
const numberOfPeople = ref(1)
// const listSelectedIngredients = ref(null)
const listIngredients = ref<Ingredient[]>([])
const user = ref(useUserStore().user)
const confirm = useConfirm()

const confirmReplaceShoppingList = (event, listSelectedIngredients) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('ingredients.addToOrders.popupLabel'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('ingredients.addToOrders.confirm'),
    rejectLabel: t('ingredients.addToOrders.cancel'),
    accept: () => {
      addToOrders(listSelectedIngredients)
    },
    reject: () => {
      console.log('reject')
    },
  })
}

const confirmDeleteShoppingList = (event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t('ingredients.deleteDishIngredients.popupLabel'),
    icon: 'pi pi-exclamation-triangle',
    acceptLabel: t('ingredients.deleteDishIngredients.confirm'),
    rejectLabel: t('ingredients.deleteDishIngredients.cancel'),
    accept: () => {
      if (!user.value || user.value.restaurants.length === 0) {
        toast.add({
          severity: 'error',
          summary: t('common.error'),
          detail: t('auth.user.noRestaurantConnected'),
          life: 3000,
        })
        return
      }
      const restaurantId = user.value.restaurants[0].id
      removeDishIngredients(dishId.value, restaurantId)
      // removeFromShoppingList()
    },
    reject: () => {
      console.log('reject')
    },
  })
}

const dishId = computed(() => {
  return route.params.id
})
onMounted(async () => {
  numberOfPeople.value = parseInt(route.query.nbPeople as string) || 1
  console.log(route.params.id)
  if (!dishesStore.selectedDish || dishesStore.selectedDish.id.toString() !== route.params.id) {
    const { data, error } = await fetchData(`${useEnvStore().apiUrl}/dishes/${dishId.value}`)
    if (error) {
      console.error(error)
    } else {
      dish.value = data as Dish
      dishesStore.selectedDish = dish.value
      console.log(data)
      numberOfPeople.value = dish.value.servings
      listIngredients.value = dish.value.ingredients
    }
  } else {
    dish.value = dishesStore.selectedDish
    numberOfPeople.value = dish.value.servings
    listIngredients.value = dish.value.ingredients
  }

  const normalizeName = (name) => {
    return name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  const matchIngredients = () => {
    listIngredients.value = listIngredients.value.map((ingredient) => {
      const matchingItem = shoppingListIngredients.value.find(
        (listItem) => listItem.original_ingredient_id === ingredient.id
      )

      if (matchingItem) {
        const quantity = matchingItem.quantity || 0
        const adjustedQuantity = adjustUnitsForQuantity(quantity, ingredient.unit)
        return {
          ...ingredient,
          is_selected: true,
          quantity: adjustedQuantity.quantity,
          price: matchingItem.price,
          unit: adjustedQuantity.unit,
        }
      }
      return ingredient
    })

    console.log(listIngredients.value)
  }
  const { data: shoppingListData, error: shoppingListError } = await fetchData(
    `${useEnvStore().apiUrl}/dishes/${dishId.value}/shopping-list`,
    'GET'
  )
  if (shoppingListError) {
    console.error(shoppingListError)
  } else {
    shoppingListIngredients.value = shoppingListData
    matchIngredients()
  }
})

function addToOrders(listSelectedIngredients: Ingredient[] | null) {
  if (listSelectedIngredients == null || listSelectedIngredients.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('ingredients.noIngredientSelected'),
      life: 3000,
    })
    console.log('No value')
    return
  }
  if (!user.value) return
  // TODO : handle if user is in many restaurants
  if (user.value.restaurants.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
    return
  }
  const restaurantId = user.value.restaurants[0].id
  if (dish.value) dish.value.hasIngredientsInShoppingList = true
  orderListStore.addIngredients(listSelectedIngredients, restaurantId, '-1')

  // storeToIngredientList(listSelectedIngredients);
  console.log('Adding to orders', listSelectedIngredients)
}

async function storeToIngredientList(listSelectedIngredients: Ingredient[]) {
  if (!user.value) return
  // TODO : handle if user is in many restaurants
  if (user.value.restaurants.length == 0) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('auth.user.noRestaurantConnected'),
      life: 3000,
    })
    return
  }
  isLoading.value = true
  const restaurantId = user.value.restaurants[0].id

  const { data, error } = await fetchData(
    useEnvStore().apiUrl + `/restaurants/${restaurantId}/ingredients`,
    'POST',
    { ingredients: listSelectedIngredients }
  )
  if (error) {
    toast.add({
      severity: 'error',
      summary: t('common.error'),
      detail: t('orders.addingIngredientsError'),
      life: 3000,
    })
    console.log('error', error)
  } else {
    console.log(data)
    toast.add({
      severity: 'success',
      summary: t('common.success'),
      detail: t('orders.addingIngredientsSuccess'),
      life: 3000,
    })
    console.log('data', data)
    orderListStore.fetchIngredients
    // router.replace({ path: `/orders/shopping-list` })
    router.go(-1);
  }
  isLoading.value = false
}

// async function removeFromShoppingList() {
//   if (!user.value) return;
//   // TODO : handle if user is in many restaurants
//   if (user.value.restaurants.length == 0) {
//     toast.add({
//       severity: 'error',
//       summary: t('common.error'),
//       detail: t('auth.user.noRestaurantConnected'),
//       life: 3000,
//     })
//     return;
//   }
//   isDeleting.value = true;
//   const restaurantId = user.value.restaurants[0].id
//   const dishId = route.params.id;
//   const { data, error } = await fetchData(
//     useEnvStore().apiUrl + `/dishes/${dishId}/shopping-list`,
//     'DELETE'
//   )
//   if (error) {
//     toast.add({
//       severity: 'error',
//       summary: t('common.error'),
//       detail: t('orders.deletingIngredientsError'),
//       life: 3000,
//     })
//     console.log('error', error)
//   } else {
//     console.log(data);
//     toast.add({
//       severity: 'success',
//       summary: t('common.success'),
//       detail: t('orders.deletingIngredientsSuccess'),
//       life: 3000,
//     })
//     orderListStore.fetchIngredients;
//     if (dish.value) dish.value.hasIngredientsInShoppingList = false;
//   }
//   isDeleting.value = false;
//   console.log("Remove ingredients")
// }

async function removeDishIngredients(dishId, restaurantId) {
  // isLoading.value = true
  try {
    const result = await orderListStore.removeDishIngredients(dishId, restaurantId)
    if (result.success) {
      // Iterate through listIngredients and unselect them
      listIngredients.value = listIngredients.value.map((ingredient) => {
        return { ...ingredient, is_selected: false }
      })
    }
  } finally {
    // isLoading.value = false
  }
}

function recalculateIngredientsForPeople(ingredients, numberOfPeople) {
  if (!dish.value) return ingredients
  return dish.value.ingredients.map((ingredient) => {
    const matchedIngredient = listIngredients.value.find((item) => item.id === ingredient.id)
    const updatedQuantity = calculateNewQuantity(ingredient, numberOfPeople)
    const updatedPrice = calculateNewPrice(ingredient, numberOfPeople)
    console.log('updatedPrice', updatedPrice)
    return {
      ...ingredient,
      quantity: updatedQuantity,
      totalPrice: updatedPrice,
      is_selected: matchedIngredient ? matchedIngredient.is_selected : false,
    }
  })
}

function calculateNewQuantity(ingredient, numberOfPeople) {
  return ingredient.quantity * numberOfPeople
}

function calculateNewPrice(ingredient, numberOfPeople) {
  return ingredient.price * numberOfPeople
}

const listSelectedIngredients = computed(() => {
  return listIngredients.value.filter((ingredient) => ingredient.is_selected)
})

const selectAll = ref(false)

// const toggleAllSelection = () => {
//   console.log("selectAll ", selectAll.value)
//   listIngredients.value.forEach(ingredient => {
//     ingredient.is_selected = selectAll.value; // Set all ingredient selections to match the main checkbox
//   });
// };

watch(
  selectAll,
  (newValue) => {
    listIngredients.value.forEach((ingredient) => {
      ingredient.is_selected = selectAll.value // Set all ingredient selections to match the main checkbox
    })
    // Check if all ingredients are selected
    // selectAll.value = listIngredients.value.every(ingredient => ingredient.is_selected);
  },
  { deep: true }
)

watch(numberOfPeople, (nbPeople) => {
  console.log(listIngredients.value)
  // console.log("Number of people updated to:", nbPeople);
  if (listIngredients.value) {
    listIngredients.value = transformAndSortIngredients(
      recalculateIngredientsForPeople(listIngredients.value, 1),
      nbPeople
    )
    console.log('new list of ingreidnet, ', listIngredients.value)
  }
  // console.log(listIngredients.value)
})
</script>

<template>
  <main>
    <div v-if="dish && (!isFetching || (!isLoading && isDeleting))">
      <h2 class="text-center">{{ t('menuDishView.addToIngredientsList') }}</h2>
      <p class="py-2 my-2 hover:cursor-pointer" @click="router.push(`/menu/dishes/${dish.id}`)">
        {{ t('menuDishView.menu')}} : <i class="pi pi-arrow-up-right"></i> {{ dish?.name }}
      </p>
      <div class="flex flex-col gap-1 mb-8">
        <label class="text-sm font-semibold text-surface-700" for="numberOfPeopleInput">
          {{ t('menuDishView.numberOfPeople') }}
        </label>
        <InputNumber
          v-model="numberOfPeople"
          inputId="horizontal-buttons"
          showButtons
          :step="1"
          :min="1"
          class="my-4"
        />
      </div>

      <!-- <InputNumber
        v-model="numberOfPeople"
        inputId="horizontal-buttons"
        showButtons
        buttonLayout="horizontal"
        :step="1"
        :min="1"
        :suffix="` ${t('menuDishView.numberOfPeopleSuffix', { count: numberOfPeople })}`"
        class="my-4"
      >
        <template #incrementbuttonicon>
          <span class="pi pi-plus" />
        </template>
        <template #decrementbuttonicon>
          <span class="pi pi-minus" />
        </template>
      </InputNumber> -->
      <div class="flex items-center gap-2 py-2 my-2 italic">
        <Checkbox inputId="select-all" v-model="selectAll" :binary="true" />
        <label for="select-all" class="ml-2 hover:cursor-pointer">{{
          t('common.selectAll')
        }}</label>
      </div>
      <SelectIngredientsList
        v-model:ingredients="listIngredients"
        :numberOfPeople="numberOfPeople"
        :dishId="dishId"
        :listSelectedIngredients="listSelectedIngredients"
      />
      <div class="flex flex-col items-end gap-4 my-4">
        <p class="font-bold">~ CHF {{ dish ? calculateDishPrice(dish, numberOfPeople) : 0 }}</p>
      </div>
      <div class="flex justify-center w-full">
        <Button
          class="col-span-2 text-center"
          size="large"
          :disabled="!dish || isDeleting"
          @click="
            dish.hasIngredientsInShoppingList
              ? confirmReplaceShoppingList($event, listSelectedIngredients)
              : addToOrders(listSelectedIngredients)
          "
        >
          {{ t('menuDishView.addToIngredientsList') }}
        </Button>
      </div>
      <div class="flex justify-center w-full" v-if="dish.hasIngredientsInShoppingList">
        <Button
          :label="t('orders.deleteAllDishIngredients')"
          @click="isDeleting ? null : confirmDeleteShoppingList($event)"
          severity="danger"
          text
          class="my-5"
        />
        <!-- <InlineLink class="my-5" color="danger" @click="isDeleting ? null : confirmDeleteShoppingList($event)"
          destition="" hierarchy="secondary" :isExternal="false">Retirer tous les ingrédients des marchandises
        </InlineLink> -->
        <!-- <Button class="col-span-2 text-center" size="large" :disabled="!dish"
          >
          Retirer tous les ingrédients des marchandises
        </Button> -->
      </div>
    </div>
    <div v-if="(isLoading || isFetching) && !isDeleting">
      <LoadingView :message="t('common.loading')" :generation="false" />
    </div>
  </main>
</template>
