<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n'
import Button from 'primevue/button'
import ButtonShare from '@/assets/icons/Buttons/ButtonShare.vue'
import ButtonWithIconWrapper from '@/components/BaseComponents/ButtonWithIconWrapper.vue'
import { usePlanningStore } from '@/stores/planningStore'

const { t, locale } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })

const props = defineProps({
  image: {
    type: String,
    required: true
  }
})
const downloadImage = (imageUrl, filename) => {
  const link = document.createElement('a');
  link.href = imageUrl;
  link.download = filename;
  document.body.appendChild(link); // Append to body to ensure visibility to DOM
  link.click();
  document.body.removeChild(link); // Clean up
};

const generateFileName = (plannedMenu) => {
  if (!plannedMenu) return 'menu';
  // fix plannedMenu name so there's no special character
  const name = plannedMenu.name.replace(/[^a-zA-Z0-9]/g, '_');
  // fix plannedMenu date so there's no special character
  const date = plannedMenu.date.replace(/[^a-zA-Z0-9]/g, '_');
  return `${name}-${date}`;
};

const shareMenu = async () => {
  const filename = generateFileName(usePlanningStore()?.selectedPlannedMenu);
  try {
    // Check if the Web Share API is available
    if (navigator.share) {
      // create a blob from the image URL
      const response = await fetch(props.image);
      const blob = await response.blob();

      // Utilise l'API Web Share pour ouvrir la modal de partage
      await navigator.share({
        title: 'Menu',
        text: 'Voici le menu à partager',
        files: [new File([blob], 'menu.png', { type: blob.type })], // Ajoute le fichier image
      });
    } else {
      // Si l'API Web Share n'est pas disponible, télécharge l'image
      downloadImage(props.image, filename);
    }
  } catch (error) {
    console.error('Erreur lors du partage ou du téléchargement :', error);
  }
};
</script>

<template>
  <main>
    <div>
      <h1 class="text-2xl mb-4 text-center">{{ t('socialNetworks.share') }}</h1>
    </div>
    <img :src="image" alt="Share Image" class="w-full rounded-t-md shadow-md max-w-[600px] mx-auto">
    <div class="shadow-md rounded-md">
    </div>
    <div class="mt-8 w-full">
      <Button class="col-span-1 w-full" @click="shareMenu()">
        <ButtonWithIconWrapper>
          <template #icon>
            <ButtonShare />
          </template>
          <template #label>
            {{ t('socialNetworks.share') }}
          </template>
        </ButtonWithIconWrapper>
      </Button>
    </div>
  </main>
</template>
