<script setup lang="ts">
// import TheMenuDishType from '@/components/Menus/Filters/TheMenuDishType.vue';
import type { MessageSchema } from '@/i18n';
// import { useDishesStore } from '@/stores/dishesStore';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import { onMounted, ref, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ChevronRightIcon from '@/assets/icons/Planning/ChevronRightIcon.vue';
import ChevronLeftIcon from '@/assets/icons/Planning/ChevronLeftIcon.vue';
import WeekMenuView from './Planning/WeekMenuView.vue';
import { usePlanningStore } from '@/stores/planningStore';
import { storeToRefs } from 'pinia';
import BaseLoader from '@/components/BaseComponents/BaseLoader.vue';
import { useUserStore } from '@/stores/useUserStore';


const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' });

// const dishesStore = ref(useDishesStore())

// const tabs: Ref<[string, string]> = ref(['week_menu', 'timetable'])
const activeTab: Ref<number> = ref(
  0
)
const user = ref(useUserStore().user)
const planningStore = usePlanningStore();
const { isLoading } = storeToRefs(planningStore);

// Formatting dates
function formatDate(date: Date): string {
  const day = String(date.getDate()).padStart(2, '0'); // Ensures 2-digit day
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensures 2-digit month
  const year = String(date.getFullYear()).slice(-2); // Gets last 2 digits of the year
  return `${day}.${month}.${year}`;
}

// watch(planningStore.weekRange, async (newRange) => {
//   console.log("Change planning Store.weekrange")

//   const restaurantId = user.value?.restaurants[0]?.id || null
//   await planningStore.fetchMenusIfNeeded(newRange.start.toISOString().split('T')[0], newRange.end.toISOString().split('T')[0], restaurantId);
// }, { deep: true, immediate: true });

onMounted(async () => {
  planningStore.fetchMenusByDateRange(planningStore.weekRange.start.toISOString().split('T')[0], planningStore.weekRange.end.toISOString().split('T')[0], user.value?.restaurants[0]?.id || null);
})
</script>

<template>
  <main>

    <div class="flex justify-center items-center my-4">
      <!-- Previous week button -->

      <button @click="planningStore.goToPreviousWeek" class="rounded-l-lg">
        <ChevronLeftIcon class="w-12 h-12 text-primary-500" />
      </button>

      <!-- Display the week number and date range -->
      <div class="px-4 py-2 text-center">
        <div>{{ t('planning.weekInfo', {weekNumber: planningStore.currentWeekNumber}) }}</div>
        <div class="text-sm">{{ formatDate(planningStore.weekRange.start) }} - {{
          formatDate(planningStore.weekRange.end) }}</div>
      </div>
      <button @click="planningStore.goToNextWeek" class="rounded-r-lg">
        <ChevronRightIcon class="w-12 h-12 text-primary-500" />
      </button>
    </div>
    <TabView :pt="{ inkbar: { class: 'hidden' } }" :pt-options="{ mergeProps: true }" v-model:activeIndex="activeTab">
      <TabPanel :pt="{
        header: { class: '!w-1/2' },
        headerAction: { class: '!justify-center' },
        content: { class: '!px-0' },
      }" :pt-options="{ mergeProps: true }" :header="t('planning.tabs.weekMenu')">
        <div v-if="isLoading" class="mt-6 mx-auto flex justify-center">
          <BaseLoader />
        </div>
        <div v-else>
          <WeekMenuView :weekRange="planningStore.weekRange" />
        </div>
      </TabPanel>
      <TabPanel :header="t('planning.tabs.absences')" :pt="{
        header: { class: '!w-1/2' },
        headerAction: { class: '!justify-center' },
        content: { class: '!px-0' },
      }">
        <div>
          Absences
        </div>
      </TabPanel>
    </TabView>
  </main>
</template>
